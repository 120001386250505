.grid1{
  margin-top: 40px;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(3,minmax(10px,1fr));
  justify-items: center;
  row-gap: 40px;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 140px;
  
  
}

.item1{
  width: 340px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  
}

.topicLink{
  display: flex;
  align-items: center;
}


.card-service1 {
  display: flex;
  justify-content: center;
  
  height: 200px;
  
}
.intro{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 5px;
}

.not_user{
  margin-top: 100px;
  font-size: 30px;
  font-weight: 800;
  color: rgb(240, 6, 57);
  animation: pop 1s infinite;
}



@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}



.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: red;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.visually-hidden{
  margin-top: 20px;
}


@media (max-width: 1080px){
  .item1{
    height: 150px;
  }
}

@media (max-width: 760px){
  .grid1{
    
    grid-template-columns: repeat(2,minmax(10px,1fr));
    
    row-gap: 30px;
    
    padding: 5px;
    margin-bottom: 10px;
    
  }
  h1{
    font-size: 20px;
  }

  h2{
    font-size: 18px;
  }
  
}

@media (max-width: 510px){
  .grid1{
    
    grid-template-columns: repeat(1,minmax(10px,1fr));
   
    
  }
  h1{
    font-size: 17px;
  }
  h2{
    font-size: 16px;
  }
}

