.header{
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #363636;
    position: sticky;
    top: 0;
    z-index: 100;
    justify-content: space-between;
    padding: 5px;
}

.login__logo1{
    font-size: 28px;
    font-weight: 1000;
    font-family: Garamond, serif;
    color: rgb(255, 255, 255);
    margin: 20px;
    

}
.login__logo1 span{
    color: #d8570d;
}


.header_optionLineTwo{
    font-size: 13px;
    font-weight: 600;
    
}

.header_nav{
   margin-right: 10%;
    display: flex;
    justify-content: space-evenly;
}

.header_options{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    
    color: white;
}
.header_optionLineOne{
    font-size: 11px;
}

.header_optionLineTwo{
    font-size: 13px;
    font-weight: 800;
}

.userName{
    color: #96dcee;
    font-weight: 700;
    font-size: 13px;
}







.modal {
    position: fixed;
    top: 40%;
    left: 30%;
    
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  
  .modal-dialog {
    max-width: 400px;
    width: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .modal-body {
    padding-bottom: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    margin-top: 10px;
  }
  
  .modal-footer button {
    margin-left: 10px;
  }






  .note-containers {
	background: white;
	position: absolute;
	display: block;
	width: 300px;
    height: 150px;
    
	padding: 2% 1%;
	
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
	border: 2px solid black;
	border-radius: 0.5em;
  z-index: 100;
}



.question-titles {
	
	
	margin-bottom: 0.5em;
	font-weight: bold;
	resize: none;
	font-size: 1.2em;
	
	padding: 0.5em 0.8em;
	word-wrap: break-word;
}

.note-sections {
	background: rgb(255, 255, 255);
	
	border: 2px solid #27ae60;
	border-radius: 5px;
	position: relative;
	margin-bottom: 0.5em;
	
	padding: 0.5em 0.8em;
	resize: none;
	height: 50px;
}
.note-sections:focus-visible {
	outline: none;
}

::placeholder {
	color: black;
	opacity: 0.5;
}
.button-containers {
	display: flex;
	align-items: right;
	flex-direction: row-reverse;
	justify-content: flex-end;
  z-index: 100;
}

.note-exits {
	
	position: relative;
	width: 5em;
	background: #dc3545;
	margin-right: 0.3em;
	border-radius: 0.3em;
	color: white;
}

.note-saves {
	
	position: relative;
	background: #27ae60;
	width: 5em;
	border-radius: 0.3em;
	margin-right: 0.3em;
	color: white;
}

.note-areas {
	
	height: 10em;
	width: 10em;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(2px);

	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
  z-index: 100;
}




@media (max-width: 485px){
    
  .login__logo1{
    font-size: 20px;
    margin: 10px;
  }

  .header_nav{
    margin-right: 5px;
 }
}
