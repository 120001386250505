.circular-loader-container {
    position: relative;
    width: 100px;
    height: 100px;
    
  }
  
  .circular-loader-container svg {
    width: 100%;
    height: 100%;
  }
  
  .circular-loader-container circle {
    stroke: #ec7608;
    stroke-width: 5;
    fill: none;
  }
  
  .circular-loader-container circle:first-of-type {
    stroke: #888683;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .circular-loader-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }


  @media (max-width: 900px){
    .circular-loader-container {
     
      width: 70px;
      height: 70px;
      margin-top: 10px;
    }
    .circular-loader-text{
      font-size: 11px;
    }
  }
  
  @media (max-width: 400px){
    .circular-loader-container {
     
      width: 50px;
      height: 60px;
      
    }
    .circular-loader-text{
      font-size: 8px;
    }
  }