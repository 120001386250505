.qstnList{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qstnListHead{
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 5px;
    color: white;
    font-size: 28px;
    font-weight: 700;
}

.qstnListHead span{
    color: rgb(211, 35, 22);
    font-size: 30px;
    font-weight: 800;
}

.table {
    border-collapse: collapse;
    width: 80%;
    margin: 20px;
}
  
.table th,
.table td {
    
    border-radius: 2px;
    padding: 8px;
    border: 1px solid black;
}

.table th{
    color: rgb(170, 168, 168);
    
}

.table td{
    color: rgb(255, 255, 255);
    padding: 8px 20px;
    
}

td a{
    color: white;
    text-decoration: none;
}

tr:nth-child(even) td{
    background-color: rgb(54, 53, 53);
}


.toast{
	background-color: #27ae60;
}


  
.note-container {

	width: 60%;
	
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: white;	
	position: absolute;
	
	border: 2px solid black;
	border-radius: 0.5em;
}

.question-title {
	width: auto;
	margin-bottom: 0.5em;
	font-weight: bold;
	resize: none;
	font-size: 1.2em;
	padding: 0.5em 0.8em;
	word-wrap: break-word;
}


.btn--close-modal {
    font-family: inherit;
    color: red;
    position: absolute;
	margin-bottom: 10px;
	margin-left: 10px;
    top: -0.9rem;
    right: 0.1rem;
    font-size: 2.4rem;
    cursor: pointer;
    border: none;
    background: none;
  }

.note-section {
	background: rgb(255, 255, 255);
	
	border: 1px solid #3fb875;
	border-radius: 5px;
	position: relative;
	margin-bottom: 0.5em;
	width: auto;
	padding: 0.5em 0.8em;
	resize: none;
	height: 22em;
}
.note-section:focus-visible {
	outline: none;
}

::placeholder {
	color: black;
	opacity: 0.5;
}
.button-container {
	display: flex;
	align-items: right;
	flex-direction: row-reverse;
	justify-content: flex-start;
}



.note-save {
	
	position: relative;
	background: #27ae60;
	width: 5em;
	border-radius: 0.3em;
	margin-right: 0.3em;
	color: white;
}

.note-area {
	display: none;
  	align-items: center;
  	justify-content: center;
	
	height: 100%;
	width: 10em;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(2px);
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
}

.urlLink{
    width: 100%;
    display: flex;
    
    justify-content: center;
}

.urlLink img{

    width: 20px;
}

.notesLogo{
    width: 100%;
    display: flex;
    cursor: pointer;
    justify-content: center;
}
.notesLogo img{

    width: 15px;
}



@media (max-width: 560px) {
    .table {
        
        width: 90%;
        
    }
    .table td,th{
      font-size: 12px;
      padding: 5px 7px;
    }

    .qstnListHead{
        
        font-size: 18px;
        font-weight: 400;
    }

    .qstnListHead span{
        
        font-size: 20px;
        font-weight: 500;
    }

    

    .note-container {
        margin-top: 20px;
        width: 80%;
    }
  }
  

