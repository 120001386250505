.login{
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:10px 0px;
}



.login__logo{
    font-size: 36px;
    font-weight: 1000;
    font-family: Garamond, serif;
    color: #111;
    margin-top: 20px;
    margin-bottom: 20px;
}
.login__logo span{
    color: #d8570d;
}

.login__container{
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 20px;
}

.login__container >h2{
    font-weight: 500;
    margin-bottom: 0px;
}

.login__container > form >h5{
    margin-bottom: 5px;
}

.login__container > form > input{
    height: 25px;
    margin-bottom: 5px;
    background-color: white;
    width: 98%;
}

.login__container > p{
    margin-top: 15px;
    font-size: 12px;
}

.login__button{
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
}

.signin__button{
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
}

