.banner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bannerIn{
    width: 95%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 27px rgb(0, 0, 0);
    margin: 50px;
    border-radius: 15px;
    padding: 20px;
    padding-left: 50px;
    color: rgb(96, 99, 128);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.banner_top{
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bannerIn h1{
    font-size: 2.5em;
}

.bannerIn p{
    font-size: 18px;
    width: 80%;
    color: rgb(117, 120, 139);
    font-weight: 500;
}

.banner_left{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: normal;
    
}

.banner_right{
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner_right img{
    width: 380px;
}


.techatphonelogo{
    
    height: 60px;
    margin-left: 10px;
}

.visit_tech{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 700;
    color: rgb(96, 99, 128);
}

.visit_tech p{
    text-align: center;
}



@media (max-width: 892px){
    .banner_top{
      flex-direction: column;
      padding-top: 50px;
      height: 400px;
  }

  .banner_left {
    width: 90%;
  }

  .visit_tech{
    margin-top: 80px;
  }
}


@media (max-width: 706px){
    .bannerIn{
        padding-left: 20px;
        margin: 30px;
    }
    .bannerIn h1{
        font-size: 1.7em;
    }

    .bannerIn p{
        font-size: 16px;
        
    }

    .visit_tech{
        font-size: 17px;
        margin-top: 20px;
      }

      .techatphonelogo{
    
        height: 40px;
        margin-left: 8px;
    }

    .banner_right img{
        width: 320px;
    }
}



@media (max-width: 485px){
    
    .bannerIn h1{
        font-size: 1.2em;
    }

    .banner_left p{
        font-size: 13px;
        width: 95%;
    }

    .visit_tech{
        font-size: 14px;
        margin-top: 0px;
      }

      

      .techatphonelogo{
    
        height: 30px;
        margin-left: 8px;
    }

    .banner_right img{
        width: 260px;
    }

    .banner_top{
        padding-top: 10px;
    }
}



@media (min-width: 1800px){
    .bannerIn{
        width: 1400px;
    }
}